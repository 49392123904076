import { Image, ImageBackground } from 'expo-image';
import React from 'react';
import { StyleSheet } from 'react-native';

import { IconSodium, Text, YStack, createMqStyles, useMqSelect } from '@fhs/ui';

type NonHeroProps = {
  displayName: string;
  description: string;
  variant: string;
  cals: string;
  imageUrl: string;
  showSodiumDisclaimer?: boolean;
};

export const NonHeroVariant = ({
  cals,
  imageUrl,
  displayName,
  variant,
  description,
  showSodiumDisclaimer,
}: NonHeroProps) => {
  const mqStyles = usqMqStyles();
  return (
    <>
      <YStack style={styles.containerTitle}>
        <Text.Heading type="one" style={styles.textTitle}>
          {displayName}
        </Text.Heading>
        <Text.Paragraph size="md" style={styles.textSubTitle}>
          {variant} • {cals}
          {showSodiumDisclaimer && (
            <>
              {' • '}
              <IconSodiumDisclaimer />
            </>
          )}
        </Text.Paragraph>
      </YStack>
      <ImageBackground
        contentFit="contain"
        source={require('../../assets/img/menu_item_detail_bg_2477_1058.webp')}
        style={styles.containerImageBackground}
      >
        <Image source={{ uri: imageUrl }} contentFit="contain" style={styles.image} />
      </ImageBackground>
      <Text.Paragraph size="md" style={[styles.textDescription, mqStyles.descriptionPadding]}>
        {description}
      </Text.Paragraph>
    </>
  );
};

function IconSodiumDisclaimer() {
  const iconSodiumSize = useMqSelect({ $ltDesktop: 16 }, 20);
  return <IconSodium size={iconSodiumSize} />;
}

const styles = StyleSheet.create({
  containerTitle: {
    marginBottom: 8,
  },
  textTitle: {
    textAlign: 'center',
    lineHeight: 33.6,
  },
  textSubTitle: {
    textAlign: 'center',
  },
  containerImageBackground: {
    flex: 1,
    minHeight: 200,
  },
  image: {
    height: '100%',
    marginTop: -10,
  },
  textDescription: {
    minHeight: 92,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
});

const usqMqStyles = createMqStyles({
  descriptionPadding: {
    $base: {
      paddingHorizontal: 16,
    },
    $gteDesktop: {
      paddingHorizontal: 8,
    },
  },
});
