import { Link, useLocalSearchParams } from 'expo-router';
import { useState } from 'react';
import { View } from 'react-native';

import { Button, YStack, createMqStyles, tokens, useMqSelect } from '@fhs/ui';
import { NutritionalInformation } from '@fhs/ui/src/components/nutritional-information';

import { useMenuPicker } from '../state/menu-picker';
import type { MenuItemCustomizationOption } from '../types';

import {
  ListItemGroup,
  ListItemGroupSection,
  ListItemLinkPressable,
} from './list-item-group-section';
import { PickerActionSheet } from './picker-action-sheet';
import { PickerAspectOptionSelector } from './picker-aspect-option-selector';
import { ExtraInstructions } from './product-detail-extra-instructions';
import { ProductDetailLayout } from './product-detail-layout';
import { ProductNameAndImage } from './product-name-and-image';
import { getOptionRenderer } from './util';

type NutritionalInformationProps = {
  isShowingNutrition: boolean;
  setIsShowingNutrition: (isShowingNutrition: boolean) => void;
};

const NutritionalInformationTile = ({
  isShowingNutrition,
  setIsShowingNutrition,
}: NutritionalInformationProps) => {
  const menuPicker = useMenuPicker();

  return (
    <View>
      <ListItemGroup
        items={[
          {
            render: ({ isFirstItem, isLastItem }) => (
              <ListItemLinkPressable
                titleFontWeight="normal"
                minHeightStyle="content"
                isFirstItem={isFirstItem}
                isLastItem={isLastItem}
                title="Nutritional Information"
                onPress={() => setIsShowingNutrition(true)}
              />
            ),
          },
        ]}
      />

      <NutritionalInformation
        nutritionFacts={{
          title: menuPicker.selectedPickerAspectOption?.item?.displayName,
          image: menuPicker.selectedPickerAspectOption?.item?.image,
          // @ts-ignore
          facts: menuPicker.selectedPickerAspectOption?.item?.nutrition ?? {},
        }}
        isVisible={isShowingNutrition}
        onClose={() => setIsShowingNutrition(false)}
      />
    </View>
  );
};

type MenuDetailsProps = NutritionalInformationProps;

export function MenuItemDetails(props: MenuDetailsProps) {
  const mqStyles = useMqStyles();
  const menuPicker = useMenuPicker();
  const params = useLocalSearchParams<{ slug: string }>();
  const requiresPickAspectOptionSelection = menuPicker.query.data?.pickerAspect.options.length > 1;
  // The option that is currently being selected in the picker action sheet
  // If the value is `null, the picker action sheet is not visible
  const [activePickerActionSheetOption, setActivePickerActionSheetOption] =
    useState<MenuItemCustomizationOption | null>(null);

  const displayGroups = menuPicker.selectedPickerAspectOption?.item?.customizations.displayGroups;
  const topLevelChoices = displayGroups?.find(
    customization => customization.displayGroup.showOnProductPage
  );

  const hasCustomizations =
    menuPicker.selectedPickerAspectOption?.item?.customizations?.displayGroups?.length > 0 || false;

  const baseCaloriesDisplay = menuPicker.selectedPickerAspectOption?.item?.baseCalories
    ? `${menuPicker.selectedPickerAspectOption?.item?.baseCalories} Cal`
    : null;

  const hasCustomizationsContent = requiresPickAspectOptionSelection || hasCustomizations;
  const asHero = useMqSelect({ $gteDesktop: true }, !requiresPickAspectOptionSelection);

  return (
    <ProductDetailLayout
      nameAndImageContent={
        <ProductNameAndImage
          displayName={menuPicker.displayName}
          description={menuPicker.selectedPickerAspectOption?.item?.description}
          variant={menuPicker.selectedPickerAspectOption?.displayName}
          cals={baseCaloriesDisplay}
          asHero={asHero}
          imageUrl={menuPicker.query.data?.image.asset.uri}
          heroNutritionalInformationTile={
            !hasCustomizationsContent && (
              <NutritionalInformationTile
                setIsShowingNutrition={props.setIsShowingNutrition}
                isShowingNutrition={props.isShowingNutrition}
              />
            )
          }
        />
      }
      customizationContent={
        hasCustomizationsContent && (
          <YStack style={mqStyles.customizationContent}>
            {requiresPickAspectOptionSelection && (
              <ListItemGroupSection heading={menuPicker.query.data?.pickerAspect?.displayName}>
                <PickerAspectOptionSelector
                  value={menuPicker.selectedPickerAspectOptionId}
                  onChange={menuPicker.setSelectedPickerAspectOptionId}
                  options={menuPicker.query.data?.pickerAspect?.options.map(opt => ({
                    value: opt.id,
                    title: opt.displayName,
                    subtitle: opt.description,
                    image: opt.image,
                  }))}
                />
              </ListItemGroupSection>
            )}
            {hasCustomizations && (
              <ListItemGroupSection heading="Customizations">
                <ListItemGroup
                  items={(topLevelChoices?.options ?? []).map(opt => ({
                    id: opt.key,
                    render: getOptionRenderer({
                      option: opt,
                      selectedModifiersKeys: menuPicker.selectedModifiersKeys,
                      setSelectedModifiersKeys: menuPicker.setSelectedModifiersKeys,
                      setActivePickerActionSheetOption: setActivePickerActionSheetOption,
                    }),
                  }))}
                  footer={
                    <Link
                      href={{
                        pathname: 'v2/menu/[slug]/customize',
                        params: {
                          slug: params.slug,
                        },
                      }}
                      asChild
                    >
                      <Button type="outline" size="md">
                        <Button.Text>Customize Ingredients</Button.Text>
                      </Button>
                    </Link>
                  }
                />
              </ListItemGroupSection>
            )}

            <ExtraInstructions orderInstructions={''} />

            <View style={mqStyles.divider} />

            <NutritionalInformationTile
              setIsShowingNutrition={props.setIsShowingNutrition}
              isShowingNutrition={props.isShowingNutrition}
            />

            {/* Bottom Sheets */}

            <PickerActionSheet
              selectedOptionKey={
                // Get the active option key based on the parent option key or default to the parent key
                menuPicker.selectedModifiersKeys[activePickerActionSheetOption?.key]
              }
              options={activePickerActionSheetOption?.options ?? []}
              isVisible={!!activePickerActionSheetOption}
              onSelect={option => {
                menuPicker.setSelectedModifiersKeys(selected => ({
                  ...selected,
                  // This is the parent option that was selected
                  [activePickerActionSheetOption.key]: option.key,
                }));
                setActivePickerActionSheetOption(null);
              }}
              onClose={() => setActivePickerActionSheetOption(null)}
            />
          </YStack>
        )
      }
    />
  );
}

const useMqStyles = createMqStyles({
  customizationContent: {
    $base: {
      paddingHorizontal: 16,
      gap: 24,
    },
  },
  divider: {
    $base: {
      height: 1,
      backgroundColor: tokens.colors.$blackOpacity04,
    },
  },
});
