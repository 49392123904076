import { Stack, useRouter } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import {
  Button,
  HeaderFrame,
  HeaderIconButton,
  IconChevronLeft,
  IconClose,
  ScrollView,
  Text,
  YStack,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';
export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

import { ListItemGroup, ListItemGroupSection } from '../components/list-item-group-section';
import { getOptionRenderer } from '../components/util';
import { useMenuPicker } from '../state/menu-picker';

const listItemProps = { titleFontWeight: 'normal' } as const;

export function ScreenCustomizeItem() {
  const router = useRouter();
  const menuPicker = useMenuPicker();

  const mqStyles = useMqStyles();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);

  const handleClose = () => router.dismiss(1);

  const displayGroups =
    menuPicker.selectedPickerAspectOption?.item?.customizations.displayGroups ?? [];

  const allCustomizations = displayGroups.filter(
    customization => !customization.displayGroup.showOnProductPage
  );

  return (
    <View style={styles.screen}>
      <Stack.Screen
        options={{
          header: () => (
            <HeaderFrame style={mqStyles.headerFrame}>
              {!isDesktop && (
                <HeaderFrame.Left>
                  <HeaderIconButton onPress={handleClose}>
                    <IconChevronLeft />
                  </HeaderIconButton>
                </HeaderFrame.Left>
              )}
              <HeaderFrame.Middle>
                <YStack style={mqStyles.headerTitleStack}>
                  <Text.Heading type={isDesktop ? 'one' : 'four'}>Customize Item</Text.Heading>
                  <Text.Paragraph size={isDesktop ? 'md' : 'sm'}>
                    {[
                      menuPicker.selectedPickerAspectOption?.displayName,
                      menuPicker.selectedPickerAspectOption?.item?.baseCalories &&
                        `${menuPicker.selectedPickerAspectOption?.item?.baseCalories} Cal`,
                    ]
                      .filter(Boolean)
                      .join(' • ')}
                  </Text.Paragraph>
                </YStack>
              </HeaderFrame.Middle>
              {isDesktop && (
                <Button
                  type="outline-subtle"
                  size="xl"
                  onPress={handleClose}
                  style={styles.desktopCloseButton}
                >
                  <Button.Icon>
                    <IconClose />
                  </Button.Icon>
                </Button>
              )}
            </HeaderFrame>
          ),
        }}
      />
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContent}>
        {allCustomizations.map((opt, i) => (
          <ListItemGroupSection
            heading={opt?.displayGroup.displayName}
            key={opt.displayGroup.id ?? i}
          >
            <ListItemGroup
              borderless
              listItemProps={listItemProps}
              items={(opt.options ?? []).map(o => ({
                id: o.key,
                render: getOptionRenderer({
                  option: o,
                  selectedModifiersKeys: menuPicker.selectedModifiersKeys,
                  setSelectedModifiersKeys: menuPicker.setSelectedModifiersKeys,
                  setActivePickerActionSheetOption: () => {},
                }),
              }))}
            />
          </ListItemGroupSection>
        ))}
      </ScrollView>
      <View style={styles.screenFooter}>
        <View style={styles.screenFooterContent}>
          <Button size="xl" onPress={handleClose}>
            <Button.Text>Looks Good!</Button.Text>
          </Button>
        </View>
      </View>
    </View>
  );
}

const MAX_CONTENT_WIDTH = 480;

const useMqStyles = createMqStyles({
  headerFrame: {
    $gteDesktop: {
      minHeight: 132,
    },
  },
  headerTitleStack: {
    $base: {
      backgroundColor: tokens.colors.$white,
      alignItems: 'center',
      gap: 4,
    },
    $gteDesktop: {
      paddingVertical: 16,
    },
  },
});

const styles = StyleSheet.create({
  screen: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    width: '100%',
  },
  scrollView: {
    flexShrink: 1,
    flexGrow: 1,
    width: '100%',
    backgroundColor: tokens.colors.$white,
  },
  scrollViewContent: {
    marginHorizontal: 'auto',
    width: '100%',
    maxWidth: MAX_CONTENT_WIDTH,
    padding: 16,
    gap: 24,
  },
  screenFooter: {
    backgroundColor: tokens.colors.$white,
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
  },
  screenFooterContent: {
    padding: 16,
    width: '100%',
    maxWidth: MAX_CONTENT_WIDTH,
    marginHorizontal: 'auto',
  },
  headerTitleStack: {
    alignItems: 'center',
  },
  desktopCloseButton: {
    position: 'absolute',
    top: 20,
    right: 32,
  },
});
