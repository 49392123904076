import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import type { MenuQueryOpts } from '../queries/types';
import { useMenuPickerQuery } from '../queries/use-menu-picker-query';
import { useMenuQueryOpts } from '../queries/use-menu-query-opts';

export type MenuPickerContext = {
  slug: string;
  query: ReturnType<typeof useMenuPickerQuery>;
  // Derived
  displayName: string;
  priceCents: number;

  // PickerAspectOption
  selectedPickerAspectOptionId: string;
  setSelectedPickerAspectOptionId: Dispatch<SetStateAction<string>>;
  selectedPickerAspectOption:
    | null
    | ReturnType<typeof useMenuPickerQuery>['data']['pickerAspect']['options'][number];

  // ModifiersKeys
  selectedModifiersKeys: Record<string, string>;
  setSelectedModifiersKeys: Dispatch<SetStateAction<Record<string, string>>>;
};

const menuPickerContext = createContext<MenuPickerContext | null>(null);

export type MenuPickerProviderProps = {
  children?: ReactNode;
  slug: string;
  menuQueryOpts?: Partial<MenuQueryOpts>;
};

const MenuPickerProviderState = (props: MenuPickerProviderProps) => {
  const slug = props.slug;

  const query = useMenuPickerQuery({
    slug,
    menuQueryOpts: useMenuQueryOpts(props.menuQueryOpts),
  });

  // PickerAspectOption
  const [_selectedPickerAspectOptionId, setSelectedPickerAspectOptionId] = useState('');
  const defaultPickerAspectOptionId = query.data?.pickerAspect.defaultOptionKey;
  const selectedPickerAspectOptionId = _selectedPickerAspectOptionId || defaultPickerAspectOptionId;
  const selectedPickerAspectOption = useMemo(
    () =>
      query.data?.pickerAspect.options.find(
        option => option.key === selectedPickerAspectOptionId
      ) ?? null,
    [query.data?.pickerAspect.options, selectedPickerAspectOptionId]
  );

  // ModifiersKeys
  const [selectedModifiersKeys, setSelectedModifiersKeys] = useState<Record<string, string>>({});

  const handlePickerAspectSelection = useCallback(
    (pickerOptionId: string) => {
      setSelectedPickerAspectOptionId(pickerOptionId);

      // Reset selected modifiers when changing picker aspect
      setSelectedModifiersKeys({});
    },
    [setSelectedPickerAspectOptionId, setSelectedModifiersKeys]
  );

  // Price
  const priceCents = useMemo(() => {
    const itemBasePriceCents = selectedPickerAspectOption?.item?.basePriceCents ?? 0;

    const displayGroups = selectedPickerAspectOption?.item.customizations.displayGroups ?? [];

    // { [optionKey]: { [optionOptionKey]: upchargeCents } }
    const customizationsMapping: Record<string, Record<string, number>> = {};
    for (const displayGroup of displayGroups) {
      for (const option of displayGroup.options) {
        const keyToPriceMapping: Record<string, number> = {};

        for (const opt of option.options) {
          keyToPriceMapping[opt.key] = opt.upChargeCents;
        }

        customizationsMapping[option.key] = keyToPriceMapping;
      }
    }

    const modifiersPriceCents = Object.entries(selectedModifiersKeys).reduce(
      (cents, [optionKey, optionOptionKey]) => {
        return cents + (customizationsMapping?.[optionKey]?.[optionOptionKey] ?? 0);
      },
      0
    );

    return itemBasePriceCents + modifiersPriceCents;
  }, [selectedPickerAspectOption, selectedModifiersKeys]);

  // Context
  const value: MenuPickerContext = useMemo(() => {
    return {
      slug,
      query,

      // Stateful
      selectedPickerAspectOptionId,
      setSelectedPickerAspectOptionId: handlePickerAspectSelection,
      selectedModifiersKeys,
      setSelectedModifiersKeys,

      // Derived
      selectedPickerAspectOption,
      displayName: query.data?.displayName ?? '',
      priceCents,
    };
  }, [
    query,
    slug,
    selectedPickerAspectOptionId,
    selectedPickerAspectOption,
    selectedModifiersKeys,
    setSelectedModifiersKeys,
    priceCents,
    handlePickerAspectSelection,
  ]);

  return <menuPickerContext.Provider value={value}>{props.children}</menuPickerContext.Provider>;
};

export const MenuPickerProvider = (props: MenuPickerProviderProps) => {
  // Reset menu picker provider state when slug changes
  return <MenuPickerProviderState {...props} key={props.slug} />;
};

export const useMenuPicker = (): MenuPickerContext => {
  const context = useContext(menuPickerContext);

  if (!context && __DEV__) {
    throw new Error(
      'useMenuPicker can only be called from within a descendant of MenuPickerProvider'
    );
  }

  return context;
};
