import { HeroVariant } from './hero-variant';
import { NonHeroVariant } from './non-hero-variant';

type ProductNameAndImageProps = {
  displayName: string;
  description: string;
  variant: string;
  cals: string;
  imageUrl: string;
  asHero?: boolean;
  heroNutritionalInformationTile?: React.ReactElement;
  showSodiumDisclaimer?: boolean;
  hasCustomizationsContent?: boolean;
};

export const ProductNameAndImage = ({ asHero, ...rest }: ProductNameAndImageProps) => {
  return asHero ? <HeroVariant {...rest} /> : <NonHeroVariant {...rest} />;
};
