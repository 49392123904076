import { useMemo } from 'react';

import { useLocale } from '@fhs-legacy/frontend/src/state/intl';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';

import type { MenuQueryOpts } from './types';

export const useMenuQueryOpts = (opts: Partial<MenuQueryOpts> = {}): MenuQueryOpts => {
  const _restaurantId = useStoreContext()?.store?.number ?? '0';
  const restaurantId = opts.restaurantId ?? _restaurantId;

  const _serviceMode = useServiceModeContext().isDelivery ? 'delivery' : 'pickup';
  const serviceMode = opts.serviceMode ?? _serviceMode;

  const _region = useLocale().region.toLowerCase() as 'us' | 'ca';
  const region = opts.region ?? _region;

  return useMemo(
    () => ({
      restaurantId,
      serviceMode,
      region,
    }),
    [restaurantId, serviceMode, region]
  );
};
