import { type MenuPickerContext } from '../state/menu-picker';
import { MenuPickerData } from '../types';

import {
  ListItemCheckbox,
  ListItemLinkPressable,
  ListItemStepper,
} from './list-item-group-section';
import type { ListItemType } from './list-item-group-section/types';

export const formatCentsToDollars = (cents: number) => {
  // Always include decimal places
  return cents > 0 ? `$${(cents / 100).toFixed(2)}` : null;
};

export const formatCentsToDollarsWithPlusSign = (cents: number) => {
  return cents > 0 ? `+ ${formatCentsToDollars(cents)}` : null;
};

// Deranged type 😅
type OptionType =
  MenuPickerData['pickerAspect']['options'][number]['item']['customizations']['displayGroups'][number]['options'][number];

export function getOptionRenderer({
  option,
  selectedModifiersKeys,
  setSelectedModifiersKeys,
  setActivePickerActionSheetOption,
}: {
  option: OptionType;
  selectedModifiersKeys: MenuPickerContext['selectedModifiersKeys'];
  setSelectedModifiersKeys: MenuPickerContext['setSelectedModifiersKeys'];
  setActivePickerActionSheetOption: (option: OptionType) => void;
}) {
  switch (option.displayType) {
    case 'CHECKBOX': {
      return (listItemProps: ListItemType) => {
        const onOption = option.options.find(o => o.multiplier > 0);
        const offOption = option.options.find(o => o.multiplier === 0);
        const checked = selectedModifiersKeys[option.key]
          ? selectedModifiersKeys[option.key] === onOption?.key
          : option.defaultOptionKey === onOption?.key;
        return (
          <ListItemCheckbox
            {...listItemProps}
            checked={checked}
            onChange={nextState =>
              setSelectedModifiersKeys(prev => ({
                ...prev,
                [option.key]: nextState ? onOption?.key : offOption?.key,
              }))
            }
            image={onOption?.image}
            title={option.displayName}
            subtitle={onOption?.caloriesOffset && `${onOption.caloriesOffset} Cal`}
            indicatorText={formatCentsToDollarsWithPlusSign(onOption?.upChargeCents)}
          />
        );
      };
    }

    case 'SELECT': {
      return (listItemProps: ListItemType) => {
        const currentValue = selectedModifiersKeys[option.key] ?? option.defaultOptionKey;
        const selectedOption = option.options.find(o => o.key === currentValue);

        return (
          <ListItemLinkPressable
            {...listItemProps}
            onPress={() => setActivePickerActionSheetOption?.(option)}
            image={selectedOption?.image}
            title={option.displayName}
            subtitle={[
              formatCentsToDollarsWithPlusSign(selectedOption.upChargeCents),
              selectedOption.displayName,
            ]
              .filter(Boolean)
              .join(' • ')}
          />
        );
      };
    }

    case 'STEPPER': {
      return (listItemProps: ListItemType) => {
        const currentValue = selectedModifiersKeys[option.key] ?? option.defaultOptionKey;
        const selectedOption = option.options.find(o => o.key === currentValue);

        return (
          <ListItemStepper
            {...listItemProps}
            options={option.options.map(o => ({
              label: o.prefix ?? o.displayName,
              value: o.key,
            }))}
            value={currentValue}
            onChangeValue={nextValue =>
              setSelectedModifiersKeys(prev => ({ ...prev, [option.key]: nextValue }))
            }
            image={selectedOption?.image}
            title={option.displayName}
            subtitle={selectedOption?.caloriesOffset && `${selectedOption.caloriesOffset} Cal`}
          />
        );
      };
    }

    default: {
      return null;
    }
  }
}
