import { StyleSheet, View, ViewProps } from 'react-native';

import { IconSodium, Text, Wordmark, createMqStyles, useMqSelect } from '@fhs/ui';
import { DeliveryFeesApply } from '@fhs-legacy/frontend/src/components/delivery-fees-apply';

import { ProductTray } from '../product-detail-layout/product-tray';

type HeroProps = {
  displayName: string;
  description: string;
  variant: string;
  cals: string;
  imageUrl: string;
  heroNutritionalInformationTile?: React.ReactElement;
  showSodiumDisclaimer?: boolean;
  hasCustomizationsContent?: boolean;
};

export const HeroVariant = ({
  cals,
  description,
  displayName,
  imageUrl,
  variant,
  heroNutritionalInformationTile,
  showSodiumDisclaimer,
  hasCustomizationsContent,
}: HeroProps) => {
  const mqStyles = useMqStyles();

  return (
    <>
      <Spacer style={styles.minHeight24} />
      <View style={styles.content}>
        {!hasCustomizationsContent && <Wordmark style={mqStyles.logo} />}
        <View style={styles.gap12}>
          <View>
            <Text weight="bold" style={[mqStyles.name]}>
              {displayName}
            </Text>
            {cals && (
              <Text.Paragraph size="lg" style={[styles.textCenter, mqStyles.cals]}>
                {variant} • {cals}
                {showSodiumDisclaimer && (
                  <>
                    {' • '}
                    <IconSodiumDisclaimer />
                  </>
                )}
              </Text.Paragraph>
            )}
          </View>
          {description && (
            <Text.Paragraph size="md" style={[styles.textCenter, mqStyles.description]}>
              {description}
            </Text.Paragraph>
          )}
          <View style={styles.centerHorizontal}>
            <DeliveryFeesApply />
          </View>
        </View>
      </View>
      <View style={mqStyles.heroNutritionalInformationTile}>{heroNutritionalInformationTile}</View>
      <Spacer style={styles.minHeight28} />
      <ProductTray source={imageUrl} />
    </>
  );
};

function Spacer(props: ViewProps) {
  return <View {...props} style={[styles.spacer, props.style]} />;
}

function IconSodiumDisclaimer() {
  const iconSodiumSize = useMqSelect({ $ltDesktop: 16 }, 20);
  return <IconSodium size={iconSodiumSize} />;
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    paddingHorizontal: 24,
    marginHorizontal: 'auto',
  },
  spacer: {
    flexGrow: 1,
  },
  minHeight28: {
    minHeight: 28,
  },
  minHeight24: {
    minHeight: 24,
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: 22.4,
  },
  gap12: {
    gap: 12,
  },
  centerHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const useMqStyles = createMqStyles({
  logo: {
    $base: {
      display: 'flex',
      height: 56,
      alignSelf: 'center',
      marginBottom: 24,
    },
    $gteDesktop: {
      display: 'none',
    },
  },
  description: {
    $gteDesktop: {
      fontSize: 'clamp(12px, 1.6vh, 16px)' as any,
      paddingVertical: 28,
    },
  },
  cals: {
    $gteDesktop: {
      fontSize: 'clamp(14px, 2.1vh, 20px)' as any,
      paddingTop: 4,
    },
  },
  name: {
    $base: {
      fontSize: 28,
      lineHeight: 28 * 1.4,
      textAlign: 'center',
    },
    $gteDesktop: {
      fontSize: 'clamp(20px, 5vh, 48px)' as any,
      lineHeight: `clamp(30px, 5vh, ${48 * 1.4}px)` as any,
    },
  },
  heroNutritionalInformationTile: {
    $ltDesktop: {
      paddingHorizontal: 16,
      paddingTop: 16,
    },
    $gteDesktop: {
      paddingVertical: 28,
    },
  },
});
